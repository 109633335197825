import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import { RedirectionType } from '@graphql/generated/graphql';
import { Button, LinkButtonProps, getRippleColor } from '@lib/uikit';
import { hrefFromAs } from '@utils/link';
import { useRouter } from 'next/router';

import InternalLink from '../links/internalLink';
import NewTabLink from '../links/newTabLink';
import Ripple from '../ripple';

export interface BaseProps extends Omit<LinkButtonProps, 'theme'> {
    url: string;
    text: string;
    onClick?: () => void;
}
export interface OutboundProps extends BaseProps {
    type: RedirectionType.Outside;
    destinationName: string;
    context: OutboundLinkContext;
    destinationCategory: OutboundLinkDestinationCategory;
    shape: OutboundLinkShape;
}

export interface InboundProps extends BaseProps {
    type: RedirectionType.Inside;
}

export type Props = OutboundProps | InboundProps;

const LinkButton: React.FC<Props> = (props: Props) => {
    const { url, text, type, onClick, ...rest } = props;
    const { company } = useRouter().query;

    if (typeof company !== 'string') return null;

    const rippleColor = getRippleColor({
        variant: rest.variant,
        colorScheme: rest.colorScheme,
    });

    return (
        <>
            {type === RedirectionType.Inside ? (
                <Button
                    {...rest}
                    className='button'
                    as={InternalLink}
                    href={hrefFromAs(url, company)}
                    onClick={onClick}
                >
                    {text}
                    <Ripple color={rippleColor} />
                </Button>
            ) : (
                <Button
                    {...rest}
                    className='button'
                    as={NewTabLink}
                    href={url}
                    destination_name={props.destinationName}
                    context={props.context}
                    shape={props.shape}
                    destination_category={props.destinationCategory}
                    onClick={onClick}
                >
                    {text}
                    <Ripple color={rippleColor} />
                </Button>
            )}
        </>
    );
};

export default LinkButton;
