import Icon, { IconName } from '@components/utils/icons/icon';
import Ripple from '@components/utils/ripple';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
    ComponentProps,
    CSSProperties,
    ElementType,
    useMemo,
} from 'react';

import { getButtonStyle, getRippleColor } from './getButtonStyle';
import { Spinner } from '../../feedback';

export type CustomButtonProps<As extends ElementType = 'button'> = {
    as?: As;
    disabled?: boolean;
    disableRipple?: boolean;
    isFullWidth?: boolean;
    isLoading?: boolean;
    buttonSize?: 'small' | 'medium' | 'large';
    colorScheme?: 'black' | 'white';
    variation?: 'primary' | 'secondary';
    variant?: 'solid' | 'outline' | 'underline' | 'ghost' | 'shadow';
    weight?: 'normal' | 'bold';
    leftIcon?: IconName;
    rightIcon?: IconName;
    maxWidth?: CSSProperties['maxWidth'];
};

export type ButtonProps<As extends ElementType = 'button'> =
    CustomButtonProps<As> & ComponentProps<As>;

const StyledButton = styled.button<ButtonProps>`
    ${getButtonStyle}
`;

export const Button = <As extends ElementType = 'button'>({
    children,
    as = 'button' as As,
    isLoading = false,
    disableRipple,
    colorScheme = 'black',
    leftIcon,
    rightIcon,
    variant = 'solid',
    ...props
}: ButtonProps<As>) => {
    const { palette } = useTheme();
    const isDisabled = useMemo(
        () => (isLoading ? true : !!props.disabled),
        [isLoading, props.disabled]
    );
    const iconColor = useMemo(() => {
        if (variant === 'solid') {
            return palette.common[colorScheme === 'black' ? 'white' : 'black'];
        }

        return palette.common[colorScheme as 'white' | 'black'];
    }, [colorScheme, palette.common, variant]);

    return (
        <StyledButton
            {...props}
            as={as}
            disabled={isDisabled}
            isLoading={isLoading}
            colorScheme={colorScheme}
            variant={variant}
        >
            {isLoading ? (
                <Spinner color={iconColor} />
            ) : (
                <>
                    {leftIcon && (
                        <Icon
                            name={leftIcon}
                            color={iconColor}
                            size={props.buttonSize}
                        />
                    )}
                    {children}
                    {rightIcon && (
                        <Icon
                            name={rightIcon}
                            color={iconColor}
                            size={props.buttonSize}
                        />
                    )}
                </>
            )}
            {!disableRipple && (
                <Ripple
                    color={getRippleColor({
                        variant: props.variant,
                        colorScheme: colorScheme,
                    })}
                />
            )}
        </StyledButton>
    );
};
