import { useTriggerEventContext } from '@components/utils/contexts/TriggerEventContext';
import { TrackEvent } from '@enums';
import { Enum_Modals_Trigger } from '@graphql/generated/graphql';
import { useAnalytics } from '@lib/analytics/analyticsContext';

import {
    UseFormSubmitMutateArgs,
    UseFormSubmitProps,
    useFormSubmit,
} from './useFormSubmit';

interface Props extends UseFormSubmitProps {
    modalName: string;
    modalUID: string;
    onCloseModal?: () => void;
}

export const useFormModalSubmit = ({
    modalName,
    modalUID,
    formName,
    formUID,
    formType,
    formContext,
    formShape,
    isProductRegistration,
    onSuccess,
    onError,
    onCloseModal,
}: Props) => {
    const { track } = useAnalytics();
    const { onTriggerEvent } = useTriggerEventContext();
    const { onSubmit, isSubmitting } = useFormSubmit({
        formName,
        formUID,
        formType,
        formContext,
        formShape,
        isProductRegistration,
        onSuccess,
        onError,
    });

    const mutate = ({ data, customerInputType }: UseFormSubmitMutateArgs) => {
        onSubmit(
            { data, customerInputType },
            {
                onSuccess: (data, variables, context) => {
                    onCloseModal?.();
                    track(TrackEvent.ModalSubmitSuccess, {
                        name: modalName,
                        modal_uid: modalUID,
                    });
                    onTriggerEvent({
                        event: Enum_Modals_Trigger.OnModalSuccessClose,
                        filters: { name: modalName, modal_uid: modalUID },
                    });
                    onSuccess?.(data, variables, context);
                },
                onError: (error, variables, context) => {
                    onCloseModal?.();
                    track(TrackEvent.ModalSubmitFailure, {
                        name: modalName,
                        modal_uid: modalUID,
                    });
                    onTriggerEvent({
                        event: Enum_Modals_Trigger.OnModalFailureClose,
                        filters: { name: modalName, modal_uid: modalUID },
                    });
                    onError?.(error, variables, context);
                    console.warn(
                        "[useFormModalSubmit]: Unable to send form's data: ",
                        error
                    );
                },
            }
        );
    };

    return { isSubmitting, onSubmit: mutate };
};
