import { TriggerEvent } from '@components/utils/contexts/TriggerEventContext';
import { Queue } from '@utils/queue';
import { useRef } from 'react';

export const useEventQueue = () => {
    const queueRef = useRef<Queue<TriggerEvent>>();

    if (!queueRef.current) {
        queueRef.current = new Queue<TriggerEvent>();
    }

    return queueRef.current;
};
