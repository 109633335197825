import { Bundle } from '@types';
import {
    isBundle,
    isFlowBundle as isFlowBundleGuard,
    isProductBundle as isProductBundleGuard,
    isStoryBundle as isStoryBundleGuard,
} from '@utils/embed/guards';
import { useRouter } from 'next/router';
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

interface EmbedContext {
    isEmbed: boolean;
    bundle?: Bundle;
    isFlowBundle: boolean;
    isProductBundle: boolean;
    isStoryBundle: boolean;
    isEmbedContextLoading: boolean;
}

const EmbedContext = createContext<EmbedContext>({} as EmbedContext);

export const EmbedProvider = ({ children }: PropsWithChildren) => {
    const { isReady, query } = useRouter();
    const [initialValues, setInitialValues] = useState<{
        isEmbed: boolean;
        bundle?: Bundle;
    }>();

    useEffect(() => {
        if (isReady && !initialValues) {
            const isEmbed = query.utm_medium === 'iframe';
            const bundle =
                isEmbed && isBundle(query.bundle) ? query.bundle : undefined;
            setInitialValues({ isEmbed, bundle });
        }
    }, [isReady, query, initialValues]);

    const isEmbed = useMemo(
        () => initialValues?.isEmbed ?? false,
        [initialValues?.isEmbed]
    );
    const bundle = useMemo(
        () => initialValues?.bundle,
        [initialValues?.bundle]
    );
    const isFlowBundle = useMemo(() => isFlowBundleGuard(bundle), [bundle]);
    const isProductBundle = useMemo(
        () => isProductBundleGuard(bundle),
        [bundle]
    );
    const isStoryBundle = useMemo(() => isStoryBundleGuard(bundle), [bundle]);

    return (
        <EmbedContext.Provider
            value={{
                isEmbed,
                bundle,
                isFlowBundle,
                isProductBundle,
                isStoryBundle,
                isEmbedContextLoading: !isReady || !initialValues,
            }}
        >
            {children}
        </EmbedContext.Provider>
    );
};

export const useEmbedContext = () => useContext(EmbedContext);
