import { Enum_Modals_Trigger, ModalFragment } from '@graphql/generated/graphql';
import { Queue } from '@utils/queue';

import { TriggerEvent } from '../TriggerEventContext';

type ModalState = {
    isOpen: boolean;
    modals?: ModalFragment[];
    currentModal?: ModalFragment;
    queue: Queue<TriggerEvent>;
};

export enum ActionType {
    EVENT_TRIGGER = 'EVENT_TRIGGER',
    SET_OPEN = 'SET_OPEN',
    SET_MODALS = 'SET_MODALS',
}

type Action =
    | {
          type: ActionType.SET_OPEN;
          isOpen: boolean;
      }
    | {
          type: ActionType.EVENT_TRIGGER;
          event: Enum_Modals_Trigger;
          filters?: Record<string, unknown>;
      }
    | {
          type: ActionType.SET_MODALS;
          modals?: ModalFragment[];
      };

const isFilterCompatible = (
    modalFilters: ModalFragment['Filter'] = {},
    eventFilters: Record<string, unknown> = {}
): boolean =>
    Object.entries(modalFilters).every(
        ([key, value]) => eventFilters?.[key] === value
    );

export const modalsReducer = (
    state: ModalState,
    action: Action
): ModalState => {
    switch (action.type) {
        case ActionType.SET_OPEN:
            return { ...state, isOpen: action.isOpen };

        case ActionType.EVENT_TRIGGER: {
            if (!state.modals) {
                throw new Error(
                    '[Modals Reducer] Event trigger was called before modal initialization'
                );
            }

            const filteredModals = state.modals.filter(
                (modal) =>
                    modal.Trigger === action.event &&
                    isFilterCompatible(modal.Filter || {}, action.filters)
            );

            if (filteredModals[0]) {
                return {
                    ...state,
                    currentModal: filteredModals[0],
                    isOpen: true,
                };
            }

            return state;
        }

        case ActionType.SET_MODALS:
            return { ...state, modals: action.modals };

        default:
            throw new Error(
                `[Modals Reducer] Unhandled action type - ${JSON.stringify(action)}`
            );
    }
};
