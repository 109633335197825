import { useModals } from '@components/utils/contexts/modals/modalsContext';
import {
    Enum_Modals_Type,
    SinglePageFormComponentFragment,
} from '@graphql/generated/graphql';
import { CustomModalProps } from '@types';
import { FC } from 'react';

import { CustomDefaultModal } from './CustomDefaultModal';
import { CustomFormModal } from './CustomFormModal';
import { CustomModalDefaultContent } from './CustomModalDefaultContent';

export const ContextualModal: FC = () => {
    const { currentModal, isOpen, onClose } = useModals();

    if (!currentModal) return null;

    const commonProps: Omit<CustomModalProps, 'type'> = {
        isOpen,
        onClose,
        name: currentModal.Name,
        UID: currentModal.UID!,
        defaultContent: <CustomModalDefaultContent modal={currentModal} />,
        elements: currentModal.Elements,
    };

    if (currentModal?.Type === Enum_Modals_Type.Form) {
        const form = currentModal.Elements.find(
            (el) => el.__typename === 'ComponentFormsForm'
        );

        return (
            <CustomFormModal
                {...commonProps}
                type={currentModal.Type}
                formName={
                    (form as SinglePageFormComponentFragment).Form?.Name || ''
                }
                formUID={
                    (form as SinglePageFormComponentFragment).Form?.UID || ''
                }
                formType={(form as SinglePageFormComponentFragment).Form!.Type}
                isProductRegistration={
                    (form as SinglePageFormComponentFragment).Form
                        ?.IsProductRegistration ?? false
                }
                customerInputType='CUSTOM'
            />
        );
    }

    return <CustomDefaultModal {...commonProps} type={currentModal.Type} />;
};
