import Tray from '@components/containers/tray';
import { VStack } from '@lib/uikit';
import { CustomDefaultModalProps } from '@types';

import { RenderDefaultModalComponent } from './RenderDefaultModalComponent';

interface Props extends Omit<CustomDefaultModalProps, 'render'> {}

export const CustomDefaultModal = ({
    defaultContent,
    elements,
    isOpen,
    onClose,
}: Props) => {
    return (
        <Tray isOpen={isOpen} onClose={onClose}>
            <VStack spacing='sm'>
                {defaultContent}
                {elements.map((element, index) => (
                    <RenderDefaultModalComponent
                        key={index}
                        component={element}
                        onCloseModal={onClose}
                    />
                ))}
            </VStack>
        </Tray>
    );
};
