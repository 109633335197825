const HttpApi = require('i18next-http-backend/cjs');

const getConfig = () => {
    const locales = process.env.NEXT_PUBLIC_LOCALES?.split(',') || [];

    if (locales.length === 0) {
        console.error('Environment variable NEXT_PUBLIC_LOCALES is not set');
    }

    return {
        i18n: {
            // IMPROVEME: Using _default to avoid conflicts with the default product.
            // Not ideal, as a conflict will arise again if a product with the "_default" slug is created.
            locales: ['_default', ...locales],
            defaultLocale: '_default',
            localeDetection: false,
        },
        backend: {
            loadPath: `${process.env.NEXT_PUBLIC_AWS_CF_URL}/static/locales/{{lng}}/{{ns}}.json`,
            allowMultiLoading: false,
            reloadIntervale: 60 * 60 * 1000, // default
        },
        use: [HttpApi],
        ns: [
            'common',
            'error',
            'home',
            'ratings',
            'search',
            'story',
            'cookies',
            'article',
            'onboarding',
            'form',
            'newsletter',
            'ai',
        ],
        serializeConfig: false,
    };
};

module.exports = getConfig();
