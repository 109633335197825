import { StyledProps, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TrackEvent } from '@enums';
import { Enum_Modals_Trigger } from '@graphql/generated/graphql';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { getTranslatedLanguageName } from '@utils/intl';
import localeDirection from '@utils/locale/direction';
import { useRouter } from 'next/router';
import { MouseEventHandler, useState } from 'react';
import { useCookie } from 'react-use';

import { useTriggerEventContext } from './contexts/TriggerEventContext';
import Icon from './icons/icon';

type PropsType = {
    className?: string;
    subLocales?: string[];
};

const LocaleText = styled.p`
    cursor: default;
    user-select: none;
    text-transform: capitalize;
    font: ${(props) => props.theme.fonts.body};
    color: ${(props) => props.theme.palette.text.primary};
    margin-inline-start: ${(props) => props.theme.spacing.sm};
`;

const Container = styled.details`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;

    & > summary {
        outline: none;
        border: none;
        list-style: none;

        & ::marker {
            display: none;
        }
    }

    & > summary:focus & > summary:active & > summary:hover & > summary:visited {
        outline: none;
        border: none;
    }

    & > summary::-webkit-details-marker {
        display: none;
    }
`;

const Summary = styled.summary`
    padding-block: ${(props) => props.theme.spacing.xxs};
    border-radius: ${(props) => props.theme.shape.radius.default};
`;

const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const List = styled.ul<StyledProps<{ isRtl?: boolean }>>`
    list-style: none;
    position: absolute;
    z-index: 9999;
    ${({ isRtl }) => (isRtl ? 'left: 0;' : 'right: 0;')}
    width: auto;
    max-width: 60ch;
    background: ${(props) => props.theme.palette.background.default};
    padding-block: ${(props) => props.theme.spacing.xxs};
    & li {
        & p {
            margin-inline-end: ${(props) => props.theme.spacing.sm};
            padding-block: ${(props) => props.theme.spacing.xxs};

            &:hover {
                font-weight: bold;
            }
        }
    }

    border-radius: ${(props) => props.theme.spacing.sm};
    box-shadow: ${(props) => props.theme.shadows.default};
`;

/* -------------------------------------------------------------------------- */
/*                                  COMPONENT                                 */
/* -------------------------------------------------------------------------- */

const LocaleSwitch: React.FC<PropsType> = ({ subLocales }: PropsType) => {
    const [open, setOpen] = useState(false);
    const router = useRouter();
    const isRtl = localeDirection(router.locale) === 'rtl';
    const { locale } = useRouter();
    const [, updateCookie] = useCookie('NEXT_LOCALE');
    const { track } = useAnalytics();
    const { onTriggerEvent } = useTriggerEventContext();
    const [locales] = useState<string[]>(subLocales || []);
    const theme = useTheme();

    const toggleOpen: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        setOpen(!open);
    };

    const translateLocale = (locale: string) => {
        const language = getTranslatedLanguageName(locale, locale);
        return language || '';
    };

    const switchLocale = (locale: string) => {
        updateCookie(locale);
        track(TrackEvent.LocalSwitched, {
            from: router.locale!,
            to: locale,
        });
        onTriggerEvent({
            event: Enum_Modals_Trigger.ChangeLocale,
            filters: { from: router.locale!, to: locale },
        });
        router.push(
            { pathname: router.pathname, query: router.query },
            router.asPath,
            { locale: locale }
        );
    };

    const handleOutside = () => {
        setOpen(false);
    };

    return locales!.length > 1 ? (
        <div>
            {locales && (
                <Container
                    className='locale_switcher_container'
                    open={open}
                    onClick={toggleOpen}
                    onBlur={handleOutside}
                >
                    <Summary>
                        <Div>
                            <LocaleText>{locale?.toUpperCase()}</LocaleText>
                            {open ? (
                                <Icon
                                    name='keyboard_arrow_down'
                                    color={theme.palette.text.primary}
                                />
                            ) : (
                                <Icon
                                    name='keyboard_arrow_right'
                                    color={theme.palette.text.primary}
                                />
                            )}
                        </Div>
                    </Summary>
                    {open && (
                        <List isRtl={isRtl}>
                            {locales
                                .filter((_locale) => _locale !== locale)
                                .map((_locale) => (
                                    <li
                                        onMouseDown={() =>
                                            switchLocale(_locale)
                                        }
                                        key={_locale}
                                    >
                                        <LocaleText>
                                            {translateLocale(_locale)}
                                        </LocaleText>
                                    </li>
                                ))}
                        </List>
                    )}
                </Container>
            )}
        </div>
    ) : null;
};

export default LocaleSwitch;
