import { useEditContext } from '@components/utils/contexts/EditContext';
import {
    getSavedPreferences,
    hasSavedPreferences,
} from '@lib/analytics/preferences';
import { AnalyticsBrowser, EventProperties } from '@segment/analytics-next';
import { IdentifyParams } from '@segment/analytics-next/dist/types/core/arguments-resolver';
import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { AnalyticsBrowserWrapper } from './analytics';
import { IS_ANALYTICS_ENABLED } from './constants';
import { useConsent } from './hooks/useConsent';
import { usePageviewEvent } from './hooks/usePageviewEvent';
import { TrackFn } from './types';

export enum EmbedCookieConsent {
    Accepted = 'accepted',
    Rejected = 'rejected',
    Ask = 'ask',
}

const AnalyticsContext = React.createContext<{
    analytics: AnalyticsBrowser;
    showConsentPrompt: boolean;
    eventProperties: EventProperties;
    track: TrackFn;
    identify: (...args: IdentifyParams) => void;
    setConsent: (consent: boolean) => void;
    setEventProperties: (events: EventProperties) => void;
}>({
    analytics: {} as AnalyticsBrowser,
    showConsentPrompt: true,
    eventProperties: {},
    setEventProperties: () => {
        // do nothing
    },
    track: () => {
        // do nothing
    },
    identify: () => {
        // do nothing
    },
    setConsent: () => {
        // do nothing
    },
});

const AnalyticsProvider = ({ children }: PropsWithChildren) => {
    const { isEditMode } = useEditContext();

    const [analytics, setAnalytics] = useState(
        new AnalyticsBrowserWrapper(IS_ANALYTICS_ENABLED)
    );
    const [eventProperties, setEventProperties] = useState<EventProperties>({});

    const disableAnalytics = useCallback(
        () => setAnalytics(new AnalyticsBrowserWrapper(false)),
        []
    );

    const resetAnalytics = useCallback(
        () => setAnalytics(new AnalyticsBrowserWrapper(IS_ANALYTICS_ENABLED)),
        []
    );

    useEffect(() => {
        if (isEditMode) disableAnalytics();
    }, [isEditMode, disableAnalytics]);

    const { showConsentPrompt, setConsent } = useConsent(
        analytics,
        resetAnalytics
    );

    usePageviewEvent(analytics);

    const loadFromPreferences = useCallback(() => {
        const preferences = getSavedPreferences();

        if (preferences?.consent) {
            analytics.loadWithConsent();
        } else {
            analytics.loadWithoutConsent();
        }
    }, [analytics]);

    useEffect(() => {
        if (hasSavedPreferences()) {
            console.log('[Analytics] Did find preference');
            loadFromPreferences();
        } else {
            console.log('[Analytics] Did NOT find preference');
            analytics.loadWithoutConsent();
        }
    }, [analytics, loadFromPreferences]);

    /* -------------------------------------------------------------------------- */
    /*                              TRACKING METHODS                              */
    /* -------------------------------------------------------------------------- */

    const track = useMemo(() => {
        if (!analytics) {
            return (eventName: string) => {
                console.error(
                    `[Analytics] Broken track function, analytics does not exist. Event : ${eventName}`
                );
            };
        }

        const memo: TrackFn = (eventName, properties) => {
            analytics.track({
                type: 'track',
                event: eventName,
                properties: {
                    ...properties,
                    ...eventProperties,
                },
            });
        };

        return memo;
    }, [analytics, eventProperties]);

    return (
        <AnalyticsContext.Provider
            value={{
                analytics,
                showConsentPrompt,
                eventProperties,
                setEventProperties,
                track,
                identify: analytics.identify,
                setConsent,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};

const useAnalytics = () => React.useContext(AnalyticsContext);

export { AnalyticsProvider, useAnalytics };
