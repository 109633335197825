import { CustomForm } from '@components/form/custom/CustomForm';
import { FormShape, OutboundLinkContext } from '@enums';
import { CustomerInputType } from '@hello-pimster/pimster-crm-sdk';
import { UseFormSubmitMutate } from '@hooks';
import { ModalElementsDynamicZoneComponent } from '@types';

import { RenderDefaultModalComponent } from './RenderDefaultModalComponent';

interface Props {
    component: ModalElementsDynamicZoneComponent;
    customerInputType?: CustomerInputType;
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
    onCloseModal: () => void;
}

export const RenderFormModalComponent = ({
    component,
    customerInputType,
    isSubmitting,
    onSubmit,
    onCloseModal,
}: Props) => {
    switch (component.__typename) {
        case 'ComponentDisplayRichText':
        case 'ComponentContentAdditionalProduct':
        case 'ComponentCtaEventCallToAction':
        case 'ComponentCtaLinkCallToAction':
        case 'ComponentCtaLockedContentCallToAction':
            return (
                <RenderDefaultModalComponent
                    component={component}
                    onCloseModal={onCloseModal}
                />
            );

        case 'ComponentFormsForm': {
            return (
                <CustomForm
                    form={component.Form}
                    customerInputType={customerInputType}
                    outboundLinkContext={OutboundLinkContext.Modal}
                    isSubmitting={isSubmitting}
                    shape={FormShape.Modal}
                    onSubmit={onSubmit}
                />
            );
        }

        default:
            return null;
    }
};
