import Icon, { IconProps } from '@components/utils/icons/icon';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, ElementType } from 'react';

type CustomProps<As extends ElementType = 'button'> = {
    as?: As;
    ariaLabel: NonNullable<HTMLButtonElement['ariaLabel']>;
    icon: IconProps['name'];
    color?: IconProps['color'];
    size?: IconProps['size'];
};

export type IconButtonProps<As extends ElementType = 'button'> =
    CustomProps<As> & Omit<ComponentProps<As>, 'children'>;

const Button = styled.button`
    pointer-events: auto;
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconButton = <As extends ElementType = 'button'>({
    as = 'button' as As,
    ariaLabel,
    icon,
    color,
    size = 'medium',
    ...rest
}: IconButtonProps<As>) => {
    const theme = useTheme();

    return (
        <Button as={as} aria-label={ariaLabel} {...rest}>
            <Icon
                name={icon}
                size={size}
                color={color || theme.palette.common.white}
            />
        </Button>
    );
};
