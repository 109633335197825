import { Enum_Modals_Trigger } from '@graphql/generated/graphql';
import { useEventQueue } from '@hooks/useEventQueue';
import { Queue } from '@utils/queue';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
} from 'react';

export type TriggerEvent = {
    event: Enum_Modals_Trigger;
    filters?: Record<string, unknown>;
};

interface TriggerEventContext {
    triggerEventQueue: Queue<TriggerEvent>;
    onTriggerEvent: (triggerEvent: TriggerEvent) => void;
}

export const TriggerEventContext = createContext<TriggerEventContext>(
    {} as TriggerEventContext
);

export const TriggerEventProvider = ({ children }: PropsWithChildren) => {
    const triggerEventQueue = useEventQueue();

    const onTriggerEvent = useCallback(
        (triggerEvent: TriggerEvent) => triggerEventQueue.enqueue(triggerEvent),
        [triggerEventQueue]
    );

    return (
        <TriggerEventContext.Provider
            value={{ triggerEventQueue, onTriggerEvent }}
        >
            {children}
        </TriggerEventContext.Provider>
    );
};

export const useTriggerEventContext = () => useContext(TriggerEventContext);
