import {
    ModalFragment,
    useGetModalsByCompanySlugQuery,
} from '@graphql/generated/graphql';
import { isSomething } from '@utils/isSomething';
import { useRouter } from 'next/router';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';

import { ActionType, modalsReducer } from './modalsReducer';
import { TriggerEvent, useTriggerEventContext } from '../TriggerEventContext';

interface ModalContext {
    currentModal?: ModalFragment;
    isOpen: boolean;
    onClose: () => void;
}

const ModalContext = createContext<ModalContext>({
    currentModal: undefined,
    isOpen: false,
    onClose: () => {},
});

const ModalProvider = ({ children }: PropsWithChildren) => {
    const router = useRouter();
    const company = router.query.company as string;
    const { triggerEventQueue } = useTriggerEventContext();

    useGetModalsByCompanySlugQuery({
        variables: { companySlug: company, locale: router.locale! },
        onCompleted: ({ companies }) => {
            dispatch({
                type: ActionType.SET_MODALS,
                modals: companies?.[0].Modals?.filter(isSomething),
            });
        },
    });

    const [{ isOpen, modals, currentModal }, dispatch] = useReducer(
        modalsReducer,
        {
            isOpen: false,
            modals: undefined,
            currentModal: undefined,
            queue: triggerEventQueue,
        }
    );

    const triggerEvent = useCallback(
        (triggerEvent: TriggerEvent) => {
            dispatch({
                type: ActionType.EVENT_TRIGGER,
                event: triggerEvent.event,
                filters: triggerEvent.filters,
            });
        },
        [dispatch]
    );

    const processQueuedEvent = useCallback(() => {
        if (isOpen) return;
        const nextEvent = triggerEventQueue.dequeue();
        if (nextEvent) triggerEvent(nextEvent);
        // After process, process the next event if needed
        if (!triggerEventQueue.isEmpty()) processQueuedEvent();
    }, [triggerEventQueue, isOpen, triggerEvent]);

    useEffect(() => {
        if (!modals) return;
        // Attach event listener to trigger processing upon receiving event
        const { removeEventListener } =
            triggerEventQueue.addEventListener(processQueuedEvent);
        return removeEventListener;
    }, [modals, triggerEventQueue, processQueuedEvent]);

    useEffect(() => {
        // Upon receiving modal or upon modal close, start processing queued events
        if (!modals || isOpen) return;
        if (!triggerEventQueue.isEmpty()) processQueuedEvent();
    }, [triggerEventQueue, isOpen, modals, processQueuedEvent]);

    const onClose = () => {
        dispatch({ type: ActionType.SET_OPEN, isOpen: false });
    };

    return (
        <ModalContext.Provider
            value={{
                currentModal,
                isOpen,
                onClose,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

const useModals = () => useContext(ModalContext);

export { ModalProvider, useModals };
