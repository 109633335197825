import { isObject } from 'lodash';

export type AdditionalProductAction = {
    [AdditionalProductActionType.SHOPIFY_ADD_TO_CART]?: ShopifyAddToCartAction;
};

export enum AdditionalProductActionType {
    SHOPIFY_ADD_TO_CART = 'shopifyAddToCart',
}

export type ShopifyAddToCartAction = {
    variantId: string | number;
};

export const isShopifyAddToCartAction = (
    value: unknown
): value is ShopifyAddToCartAction => {
    if (!isObject(value)) return false;
    if (!('variantId' in value)) return false;
    if (!Number.isInteger(Number(value.variantId))) return false;
    return true;
};

export const hasShopifyAddToCartAction = (
    value: unknown
): value is {
    [AdditionalProductActionType.SHOPIFY_ADD_TO_CART]: ShopifyAddToCartAction;
} => {
    if (!isObject(value)) {
        return false;
    }

    if (
        AdditionalProductActionType.SHOPIFY_ADD_TO_CART in value &&
        isShopifyAddToCartAction(
            value[AdditionalProductActionType.SHOPIFY_ADD_TO_CART]
        )
    ) {
        return true;
    }

    return false;
};
