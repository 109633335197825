import { Bundle } from '@types';

export const isBundle = (value: unknown): value is Bundle => {
    return Object.values(Bundle).includes(value as Bundle);
};

export const isFlowBundle = (value: unknown): value is Bundle.Flow => {
    return value === Bundle.Flow;
};

export const isProductBundle = (value: unknown): value is Bundle.Product => {
    return value === Bundle.Product;
};

export const isStoryBundle = (value: unknown): value is Bundle.Story => {
    return value === Bundle.Story;
};
